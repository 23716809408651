import './styles.css';
import url from './url';
import $ from 'jquery';
import moment from 'moment';

const DISPLAY_WEEK = 'week';
const DISPLAY_MONTH = 'month';
const LEVELS = {
  '1': 'Tous niveaux',
  '2': 'Débutant',
  '3': 'Intermédiaire',
  '4': 'Avancé',
};

function formatTime(date) {
  return date.format('H:mm');
}

function parseUrl() {
  const [BASE_URL, URL_PARAMS] = window.location.href.split('?');
  const filters = [];
  let company = null;
  let displayMode = 'week';
  if (URL_PARAMS) {
    URL_PARAMS.split('&').map((filter_) => {
      const [field, query] = filter_.split('=');
      if (field === 'company') {
        company = query;
      } else if (field === 'displayMode') {
        displayMode = query;
      } else if (field && query) {
        filters.push([field, query]);
      }
    });
  }
  return [BASE_URL, company, displayMode, filters];
}

function filterEvents(events, filters) {
  return events.filter((ev) => {
    let ok = true;
    for (const filter_ of filters) {
      const queryParsed = JSON.parse(filter_[1]);
      if (typeof queryParsed === 'number') {
        if (ev[filter_[0]] != filter_[1]) {
          ok = false;
        }
      } else if (Array.isArray(queryParsed)) {
        const d = ev[filter_[0]];
        if (!queryParsed.map((qp) => `${qp}`).includes(d)) {
          ok = false;
        }
      }
    }
    return ok;
  });
}

class Calendar {
  constructor(selector, events, initialDisplayMode) {
    this.displayMode = initialDisplayMode;
    this.el = document.querySelector(selector);
    this.events = events;
    this.today = moment();
    this.selectedDay = null;
    if (this.displayMode === DISPLAY_WEEK) {
      this.referenceDay = moment().subtract((moment().day() + 6) % 7, 'days');
    } else {
      this.referenceDay = moment().date(1);
    }
    this.draw();
  }

  draw = () => {
    this.drawHeader();
    this.drawMonth();
    this.drawEventList();
  };

  drawHeader = () => {
    if (this.header) {
      this.header.parentNode.removeChild(this.header);
    }
    //Create the header elements
    this.header = createElement('div', 'header');
    this.header.className = 'header';

    if (this.displayMode === DISPLAY_WEEK) {
      const showFullCalendarButton = createElement(
        'button',
        'showFull-button',
        'Voir le calendrier complet',
      );

      showFullCalendarButton.addEventListener('click', () => {
        this.displayMode = DISPLAY_MONTH;
        this.referenceDay = moment().date(1);
        this.draw();
      });
      const showFullCalendarButtonContainer = createElement(
        'div',
        'showFull-button-container',
      );
      showFullCalendarButtonContainer.appendChild(showFullCalendarButton);
      this.header.appendChild(showFullCalendarButton);
    } else if (this.displayMode === DISPLAY_MONTH) {
      this.title = createElement('h1');

      var right = createElement('div', 'right');
      right.addEventListener('click', () => {
        this.nextMonth();
      });

      var left = createElement('div', 'left');
      left.addEventListener('click', () => {
        this.prevMonth();
      });
      this.header.appendChild(this.title);
      this.header.appendChild(right);
      this.header.appendChild(left);
      this.title.innerHTML = this.referenceDay.format('MMMM YYYY');
    }

    //Append the Elements
    this.el.appendChild(this.header);
    this.drawMonthHeader();
  };

  drawEventList() {
    this.oldEventList = this.eventListDiv;
    if (this.oldEventList) {
      this.oldEventList.parentNode.removeChild(this.oldEventList);
    }

    this.eventListDiv = createElement('div', 'event-list-container');
    if (!this.selectedDay) {
      this.eventListDiv.appendChild(
        createElement('div', 'event-list-header', 'Sélectionnez une date'),
      );
    } else {
      this.eventListDiv.appendChild(
        createElement(
          'div',
          'event-list-header',
          this.selectedDay.format('ddd DD MMM'),
        ),
      );
      const eventList = createElement('ul', 'event-list');

      const eventDivList = this.getEventsForDay(this.selectedDay);
      if ((eventDivList || []).length === 0) {
        eventList.appendChild(
          createElement('div', 'event-list-item', 'Aucune activité'),
        );
      } else {
        eventDivList.map((ev) => {
          eventList.appendChild(this.createEventListItem(ev));
        });
      }

      this.eventListDiv.appendChild(eventList);
    }
    this.el.appendChild(this.eventListDiv);
  }

  createEventListItem(ev) {
    const date_start = moment(ev.date_start);
    const date_end = moment(ev.date_start).add(ev.duration_minute, 'minutes');

    const eventListItem = createElement('li', 'event-list-item');
    const eventInfo = createElement('div', 'event-info');
    const button = createElement('button', 'book-button', 'Réserver');

    button.addEventListener('click', () => {
      const urlToOpen = url.bookAnOffer(ev.id, COMPANY_PK);
      const win = window.open(urlToOpen, '_blank');
      win.focus();
    });

    const title = createElement(
      'div',
      'event-title',
      `${formatTime(date_start)} - ${formatTime(date_end)}`,
    );
    const subtitle = createElement(
      'div',
      'event-subtitle',
      `${ev.activity_name} - ${LEVELS[ev.level_id]}`,
    );
    const infoContent = createElement(
      'div',
      'event-content',
      `${ev.coach_override || ev.coach} - ${ev.establishment}`,
    );
    const infoContentBis = createElement(
      'div',
      'event-content-address',
      ev.address,
    );

    eventInfo.appendChild(title);
    eventInfo.appendChild(subtitle);
    eventInfo.appendChild(infoContent);
    eventInfo.appendChild(infoContentBis);

    eventListItem.appendChild(eventInfo);
    eventListItem.appendChild(button);

    return eventListItem;
  }

  drawMonth() {
    if (this.month) {
      this.oldMonth = this.month;
      this.oldMonth.className = 'month out ' + (this.next ? 'next' : 'prev');
      this.oldMonth.parentNode.removeChild(this.oldMonth);
      this.month = createElement('div', 'month');
      this.backFillWeek();
      this.currentMonth();
      this.forwardFillWeek();
      this.el.appendChild(this.month);
      this.month.className = 'month in ' + (this.next ? 'next' : 'prev');
    } else {
      this.month = createElement('div', 'month');
      this.el.appendChild(this.month);
      this.backFillWeek();
      this.currentMonth();
      this.forwardFillWeek();
      this.month.className = 'month new';
    }
  }

  drawMonthHeader() {
    if (this.daysOfWeekDiv) {
      this.daysOfWeekDiv.parentNode.removeChild(this.daysOfWeekDiv);
    }
    this.daysOfWeekDiv = createElement('div', 'month-header');
    // const weekdays = [
    //   ...moment.weekdaysShort().slice(1, 7),
    //   moment.weekdaysShort()[0],
    // ];
    moment.weekdaysShort().map((wd) => {
      var name = createElement('span', 'day-name', wd);
      this.daysOfWeekDiv.appendChild(name);
    });
    this.el.appendChild(this.daysOfWeekDiv);
  }

  backFillWeek() {
    var clone = this.referenceDay.clone();
    var dayOfWeek = clone.day() - 1;

    if (!dayOfWeek) {
      return;
    }

    clone.subtract(dayOfWeek + 1, 'days');

    for (var i = dayOfWeek; i > 0; i--) {
      this.drawDay(clone.add(1, 'days'));
    }
  }

  forwardFillWeek() {
    if (this.displayMode === DISPLAY_MONTH) {
      var clone = this.referenceDay.clone();
      clone.add(1, 'months');
      var dayOfWeek = clone.day();

      if (dayOfWeek === 1) {
        return;
      }

      for (var i = dayOfWeek - 2; i < 6; i++) {
        if (clone.day() === 1) {
          return; // unused
        }

        this.drawDay(clone);
        clone.add(1, 'days');
      }
    }
  }

  currentMonth() {
    if (this.displayMode == DISPLAY_WEEK) {
      const clone = this.referenceDay.clone();
      while (
        clone.week() === this.referenceDay.week() ||
        (clone.week() === this.referenceDay.week() + 1 &&
          this.referenceDay.day() === 0)
      ) {
        this.drawDay(clone);
        clone.add(1, 'days');
      }
    } else {
      const clone = this.referenceDay.clone().date(1);
      while (clone.month() === this.referenceDay.month()) {
        this.drawDay(clone);
        clone.add(1, 'days');
      }
    }
  }

  getWeek(day) {
    if (!this.week || day.day() === 0) {
      this.week = createElement('div', 'week');
      this.month.appendChild(this.week);
    }
  }

  drawDay = (day) => {
    this.getWeek(day);

    //Outer Day
    var outer = createElement('div', this.getDayClass(day));
    if (
      day.endOf('day').isAfter(moment()) &&
      day.month() === this.referenceDay.month()
    ) {
      outer.addEventListener('click', () => {
        this.openDay(outer);
        [...document.getElementsByClassName('selected-day')].map((e) => {
          e.classList.remove('selected-day');
        });
        outer.classList.add('selected-day');
      });
    }

    //Day Name

    //Day Number
    var number = createElement('div', 'day-number', day.format('DD'));

    //Events
    var eventsDiv = createElement('div', 'day-events');
    this.drawEvents(day, this.events, eventsDiv);

    outer.appendChild(number);
    outer.appendChild(eventsDiv);
    this.week.appendChild(outer);
  };

  drawEvents(day, events, eventsDiv) {
    if (
      day.month() === this.referenceDay.month() &&
      day.year() === this.referenceDay.year()
    ) {
      const memo = [];
      events.map((ev) => {
        if (
          moment(ev.date_start).isSame(moment(day), 'day') &&
          memo.length < 1
        ) {
          memo.push(ev);
        }
      });

      memo.forEach(function(ev) {
        var evSpan = createElement('span', ev.color || 'black');
        eventsDiv.appendChild(evSpan);
      });
    }
  }

  getDayClass(day) {
    const classes = ['day'];
    if (day.month() !== this.referenceDay.month()) {
      classes.push('other');
    } else if (this.today.isSame(day, 'day')) {
      classes.push('today');
      classes.push('selectable-day');
    } else if (day.endOf('day').isBefore(moment())) {
      classes.push('old-day');
    } else if (day.month() === this.referenceDay.month()) {
      classes.push('selectable-day');
    }
    return classes.join(' ');
  }

  getEventsForDay(day) {
    return this.events.reduce(function(memo, ev) {
      if (moment(ev.date_start).isSame(day, 'day')) {
        memo.push(ev);
      }
      return memo;
    }, []);
  }

  openDay(el) {
    var dayNumber =
      +el.querySelectorAll('.day-number')[0].innerText ||
      +el.querySelectorAll('.day-number')[0].textContent;
    var day = this.referenceDay.clone().date(dayNumber);
    this.selectedDay = day;
    this.drawEventList();
  }

  nextMonth() {
    this.selectedDay = null;
    this.referenceDay.add(1, 'months');
    this.next = true;
    this.draw();
  }

  prevMonth() {
    this.referenceDay.subtract(1, 'months');
    this.selectedDay = null;
    this.next = false;
    this.draw();
  }
}

function createElement(tagName, className, innerText) {
  var ele = document.createElement(tagName);
  if (className) {
    ele.className = className;
  }
  if (innerText) {
    ele.innderText = ele.textContent = innerText;
  }
  return ele;
}

moment.locale('fr');
const [BASE_URL, COMPANY_PK, INITIAL_DISPLAY_MODE, FILTERS] = parseUrl();

export default function renderCalendar() {
  $('#mySpinner').addClass('spinner');
  $.get(
    url.offers(COMPANY_PK),
    (data) => {
      $('#mySpinner').removeClass('spinner');
      const cal = new Calendar(
        '#calendar',
        filterEvents(data, FILTERS),
        INITIAL_DISPLAY_MODE,
      );
      window.calendar = cal;
    },
    'json',
  );
}
