const BSPORT_API = 'https://bsport.io/api/v1/external';

export function offers(companyPk) {
  return `${BSPORT_API}/company/${companyPk}/offers`;
}
export function bookAnOffer(offerId, companyPk) {
  return (
    'https://backoffice.bsport.io/customer/payment/offer/' +
    offerId +
    '?membership=' +
    companyPk
  );
}

export default {
  offers,
  bookAnOffer,
};
